import { useState, useRef } from 'react'
import { connect } from 'react-redux';

import ALink from '~/components/features/alink';

import { actions as cartAction } from '~/store/cart';
import { actions as wishListAction } from '~/store/wishlist';
import { actions as compareAction } from '~/store/compare';


import Axios from 'axios';
import Swal from 'sweetalert2';
import { isInCompare, isInWishlist } from '~/utils';
import Qty from '~/components/features/qty';

function Detail(props) {
    const ref = useRef(null);
    const { product, wishlist, language, lang, directLogout, userData } = props;
    const [qty, setQty] = useState(1);
    const [prices, setPrices] = useState([product.prices[0]]);
    const [selectedUnit, setSelectedUnit] = useState(product.prices[0].unit_id);

    let onCartClick = async (qty) => {
        if (qty > prices[0].qty) {
            Swal.fire(language.warning, `${language.qtyError} ${product.stock}`, 'warning');
        } else {
            if (0 !== product.stock) {
                await Axios({
                    method: 'post',
                    responseType: 'json',
                    url: '/api/basket/',
                    headers: {
                        "Authorization": userData ? userData.token : null,
                    },
                    data: {
                        action: 'basketAdd',
                        productID: product.id,
                        unit: parseInt(prices[0].unit_id),
                        branchId: prices[0].branch_id,
                        qty: qty,
                        userToken: window.localStorage.getItem('userToken')
                    }
                }).then((addBasket) => {
                    if (addBasket.data.status === 1) {
                        props.addToCart(product, qty, lang);
                    } else if (addBasket.data.status === 7) {
                        Swal.fire(language.warning, language.maximumQty, 'error');
                    } else if (addBasket.data.status === 8) {
                        directLogout();
                    }
                })
            }
        }
    }
    async function onWishlistClick(e) {
        e.preventDefault();
        if (!isInWishlist(props.wishlist, product)) {
            if (userData.token) {
                await Axios({
                    method: 'post',
                    responseType: 'json',
                    url: '/api/basket/',
                    headers: {
                        "Authorization": userData ? userData.token : null,
                    },
                    data: {
                        action: 'addWishList',
                        productID: product.id,
                    }
                }).then(({ data }) => {
                    if (data.status === 1) {
                        props.addToWishlist(product, true, lang);
                    }
                })
            } else {
                props.addToWishlist(product, true, lang);
            }
        } else {
            router.push('/my-account/my-favorites');
        }
    }

    let removeFromWishlistHandler = async (e) => {
        e.preventDefault();
        await Axios({
            method: 'post',
            responseType: 'json',
            url: '/api/basket/',
            headers: {
                "Authorization": userData ? userData.token : null,
            },
            data: {
                action: 'deleteWishList',
                productID: product.id,
            }
        }).then(({ data }) => {
            if (data.status === 1) {
                props.removeFromWishlist(product, lang)
            }
        })
    }

    function onChangeQty(current) {
        setQty(current);
    }

    function onCompareClick(e) {
        e.preventDefault();
        if (!isInCompare(props.comparelist, product)) {
            props.addToCompare(product, lang);
        } else {
            props.removeFromCompare(product, lang);
        }
    }

    if (!product) {
        return <div></div>;
    }

    return (
        <>
            <div className="product-details position-relative" ref={ref}>
                <h6 className="product-brand">
                    <ALink href={'/brands/?brand=' + product.brand_id}>
                        {product.brand}
                    </ALink>
                </h6>

                <div className="row">
                    <div className="col-lg-7 col-sm-12">
                        <h1 className="product-title">{product.name} <small>({prices[0].unit})</small></h1>
                    </div>
                    <div className="col-lg-5 col-sm-12">
                        {
                            prices[0].discount_price ?
                                <div className="product-price d-flex">
                                    <span className="new-price">{prices[0].discount_price.toFixed(2)} SAR</span>
                                    <span className="old-price">{prices[0].price.toFixed(2)} SAR</span>
                                    <div className='price-discount'><img src="/images/icons/discount-rate-icon.svg" width={12} alt="discount rate" /> %{product.discount} </div>
                                </div>
                                :
                                <div className="product-price">{prices[0].price.toFixed(2)} SAR </div>
                        }
                    </div>
                </div>

                <div className="d-flex align-items-center my-3">
                    <div>
                        <div className="ratings-container">
                            <div className="ratings">
                                <div className="ratings-val" style={{ width: product.ratings * 20 + '%' }}></div>
                            </div>
                            <span>{product.ratings}</span>
                            <span className="ratings-text">( {product.reviews} )</span>
                        </div>
                    </div>
                    <span className='mx-3'>|</span>
                    <div className='text-success'>
                        SKU {product.sku}
                    </div>
                </div>


                {
                    product.prices.length > 1 &&
                    <div className="d-flex flex-wrap my-1">
                        {
                            product.prices.map((price, index) =>
                                <div className={`units${price.unit_id == selectedUnit ? ' active' : ''}`} key={index}>
                                    <input type="radio" id={"unit" + index} className='d-none' name="unit" required value="payAtTheDoor" checked={price.unit_id == selectedUnit} onChange={() => { setSelectedUnit(price.unit_id); setPrices([price]) }} />
                                    <label htmlFor={"unit" + index}>{price.unit}</label>
                                </div>
                            )
                        }
                    </div>
                }
                <div className='product-description'>
                    <p dangerouslySetInnerHTML={{ __html: product.longDesc }}></p>
                </div>
                {
                    product.stock > 0 &&
                    <>
                        <div className="product-details-action">
                            <div className="d-flex align-items-center gap-3">
                                <div>
                                    <Qty value={1} min="1" step="1" max={product.stock} lang={lang} changeQty={current => onChangeQty(current)} />
                                </div>
                                <div>
                                {
                                    <button
                                    type="button"
                                    className={`btn-cart`}
                                    onClick={() => { onCartClick(qty) }}
                                    >
                                        {/* <img src='/images/icons/add-to-card-icon.svg' width={40} alt='add-to-cart' /> */}
                                        <span>{language.addToCart}</span>
                                    </button>
                                }
                                </div>
                            </div>

                            <div className='d-flex align-items-center'>
                                {
                                    userData?.token &&
                                        isInWishlist(wishlist, product) ?
                                        <a href="#" className="btn-product-icon btn-wishlist added-to-wishlist" onClick={removeFromWishlistHandler}></a>
                                        :
                                        <a href="#" className="btn-product-icon btn-wishlist" onClick={onWishlistClick}></a>
                                }
                                <button type='button' className="btn-product-icon btn-compare" title={language.compare} onClick={onCompareClick}><span>{language.compare}</span></button>

                                <div className="social-share">
                                    <label htmlFor="share">{language.share}:</label>
                                    <div className="share-item">
                                        <ALink href={`https://www.facebook.com/sharer/sharer.php?u=https://mkhazin-store.com/product/${product.url}`}><img src='/images/icons/facebook.svg' width={20} /></ALink>
                                    </div>
                                    <div className="share-item">
                                        <ALink href={`https://x.com/intent/tweet?text=https://mkhazin-store.com/product/${product.url}`}><img src='/images/icons/x.svg' width={20} /></ALink>
                                    </div>
                                    <div className="share-item">
                                        <ALink href={`whatsapp://send?text=https://mkhazin-store.com/product/${product.url}`}><img src='/images/icons/whatsapp.svg' width={20} /></ALink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {
                    product.productTags.length > 0 &&
                    <div className='product-tags'>
                        <div className="d-flex align-items-center mt-1 mb-2">
                            <img src='/images/icons/tags.svg' width={21} />
                            <h4 className='fw-bold m-0'>{language.tags}</h4>
                        </div>
                        <ul className='product-tags-list'>
                            {
                                product.productTags.map((x, y) =>
                                    <li key={y}><a href={`/search/?tag=${x.id}`}>{x.name}</a></li>
                                )
                            }

                        </ul>
                    </div>
                }
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        cartlist: state.cartlist.data,
        wishlist: state.wishlist.data,
        comparelist: state.comparelist.data
    }
}

export default connect(mapStateToProps, { ...cartAction, ...wishListAction, ...compareAction })(Detail);
