import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import ALink from '~/components/features/alink';
import Axios from 'axios';

function MainMenu({ lang, language }) {
    const router = useRouter();
    let path = router.asPath;
    let query = router.query;
    const [categories, setCategories] = useState([]);
    // const [randomCategory, setRandomCategory] = useState([]);
    let getCategories = async () => {
        await Axios({
            method: 'post',
            responseType: 'json',
            url: 'api/categories/',
            data: {
                lang: lang
            },
        }).then((categories) => {
            setCategories(categories.data['categories']);
            // let categoryLength = categories.data['categories'].length;
            // setRandomCategory(categories.data['categories'][Math.floor(Math.random() * categoryLength)]);
        })
    }

    useEffect(() => {
        getCategories();
    }, [lang])

    return (
        <nav className="main-nav">
            <ul className="menu sf-arrows">
                <li className={path.indexOf("/shop") > -1 ? 'active' : ''}>
                    <ALink href="/#" scroll={false}>
                        <img src='/images/icons/menu-icon.svg' width={20} alt='menu-icon' />
                    </ALink>
                    <div className="megamenu megamenu-md">
                        <div className="row no-gutters bg-white">
                            <div className="container bg-white">
                                {
                                    categories.length > 0 &&
                                    <>
                                        <div className="col-md-12">
                                            <div className="menu-col">
                                                <div className="row">
                                                    {
                                                        categories.map((category, y) =>
                                                            <div className="col-md-4" key={y}>
                                                                <div className="menu-title"><ALink href={`${process.env.PUBLIC_URL + 'c/' + category.catUrl}`} scroll={false}>{category.catName}</ALink></div>
                                                                <ul className='mb-1'>
                                                                    {
                                                                        category.subCategories.length > 0 &&
                                                                        category.subCategories.map((subCategory, suby) =>
                                                                            <li key={suby}><ALink href={`${process.env.PUBLIC_URL + 'c/' + subCategory.catUrl}`} scroll={false}>{subCategory.catName}</ALink></li>
                                                                        )
                                                                    }
                                                                </ul>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
    );
}

export default MainMenu;