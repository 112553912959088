import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

function Qty(props) {
    const { value = 1, adClass = "", max = 10000, changeQty, lang } = props;
    const [current, setCurrent] = useState(value);

    useEffect(() => {
        setCurrent(value);
    }, [value])

    useEffect(() => {
        changeQty && changeQty(current);
    }, [current])

    function increment() {
        if (max <= 0 || current >= max) {
            if (lang == 'ar') {
                Swal.fire({
                    title: 'تحذير',
                    text: 'لا توجد كميات إضافية في المخزن حالياً',
                    icon: 'warning',
                    confirmButtonText: 'حسناً'
                })
            } else {
                Swal.fire('Warning', 'Additional quantity not available in stock', 'warning');
            }
            return;
        } else {
            setCurrent(current + 1);
        }
    }

    function decrement() {
        if (current > 1) {
            setCurrent(current - 1);
        }
    }

    function changeCurrent(e) {
        setCurrent(parseInt(e.currentTarget.value));
    }

    function checkQuantity(e) {
        if (parseInt(e.currentTarget.value) > max) {
            setCurrent(max);
        }
    }

    return (
        <div className={`product-details-quantity ${adClass}`}>
            <div className="input-group input-spinner">
                <div className="input-group-prepend">
                    <button
                        style={{ minWidth: '26px' }}
                        className="btn btn-decrement btn-spinner"
                        onClick={decrement}
                        type="button"
                    >
                        <img src='/images/icons/remove-from-card-icon.svg' width={26} alt='quantity down' />
                    </button>
                </div>
                <input
                    type="number"
                    className="form-control"
                    min="1"
                    max={max}
                    value={current}
                    required
                    onChange={changeCurrent}
                    onBlur={checkQuantity}
                />
                <div className="input-group-append">
                    <button
                        style={{ minWidth: '26px' }}
                        className="btn btn-increment btn-spinner"
                        type="button"
                        onClick={increment}
                    >
                        <img src='/images/icons/add-to-card-icon.svg' width={26} alt='quantity down' />
                    </button>
                </div>
            </div>
        </div >
    )
}

export default Qty;