import { useContext, useState } from 'react';
import ALink from '~/components/features/alink';
import React, { useEffect } from 'react';
import { LangContext } from '~/context/lang';
import { isMobile } from 'react-device-detect';

function Footer({ content }) {
    let { getLang } = useContext(LangContext);
    let language = getLang("footer");
    const [showCookieBanner, setShowCookieBanner] = useState('');

    function cookieBanner(mode = 'approve') {
        window.localStorage.setItem('cookieBanner', mode);
        setShowCookieBanner(mode)
    }

    useEffect(() => {
        let cookieBanner = window.localStorage.getItem('cookieBanner');
        if (cookieBanner) {
            setShowCookieBanner(cookieBanner)
        }
    });
    return (
        <footer className="footer fw-normal second-primary-color" >
            <div className="footer-middle border-0">
                <div className="container">
                    <div className="d-flex justify-content-center">
                     
                        <ul className='footer-social'>
                            {
                                content[1].facebook != "" &&
                                <li><ALink href={content[1].facebook}><img src='/images/icons/facebook.svg' alt='facebook' /></ALink></li>
                            }
                            {
                                content[1].twitter != "" &&
                                <li><ALink href={content[1].twitter}><img src='/images/icons/x.svg' width={18} alt='twitter' /></ALink></li>
                            }
                            {
                                content[1].instagram != "" &&
                                <li><ALink href={content[1].instagram}><img src='/images/icons/instagram.svg' alt='instagram' /></ALink></li>
                            }
                            {
                                content[1].snapchat != "" &&
                                <li><ALink href={content[1].snapchat}><img src='/images/icons/snapchat.svg' alt='snapchat' /></ALink></li>
                            }
                            {
                                content[1].tiktok != "" &&
                                <li><ALink href={content[1].tiktok}><img src='/images/icons/tiktok.svg' alt='tiktok' /></ALink></li>
                            }
                        </ul>
                    </div>
                </div>
                <hr className='m-0' />
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-lg-4 pt-6">
                            <ALink href="/">
                                <img src="images/logo.svg" className="footer-logo mb-1" alt="Footer Logo" width={105} />
                            </ALink>
                            <p>{content[0].footer_text}</p>
                        </div>
                        <div className="col-sm-6 col-lg-2 pt-2">
                            <div className="widget">
                                <h4 className="widget-title">{language.quickLinks}</h4>
                                <ul className="widget-list">
                                    {
                                        isMobile &&
                                        <li><ALink href="/contact">{language.contactUs}</ALink></li>
                                    }
                                    <li><ALink href="/about">{language.whoWeAre}</ALink></li>
                                    <li><ALink href="/shipping-delivery">{language.shippingDelivery}</ALink></li>
                                    <li><ALink href="/exchange-return">{language.exchangeReturn}</ALink></li>
                                    <li><ALink href="/privacy">{language.privacyPolicy}</ALink></li>
                                    <li><ALink href="/usage-policy">{language.usagePolicy}</ALink></li>
                                    <li><ALink href="/faq">{language.faq}</ALink></li>
                                    <li><ALink href="/commerical-register">{language.commericalRegister}</ALink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 pt-2">
                            <div className="widget">
                                <h4 className="widget-title">{language.importantInfo}</h4>
                                <ul className='payment-accepted'>
                                    <li>{language.taxNumber}: <span>{content[0].tax_number}</span></li>
                                    {
                                        content[6].map((i, k) =>
                                            i.integration == 'myfatoorah' ?
                                                <>
                                                    <li><img src='/images/icons/visa.svg' alt='visa' /></li>
                                                    <li><img src='/images/icons/apple-pay.svg' alt='apple' /></li>
                                                </>
                                                :
                                                i.integration == 'urway' ?
                                                    <li><img src='/images/urway-logo.png' width={75} alt='urway' /></li>
                                                    :
                                                    i.integration == 'moyasar' ?
                                                        <li><img src='/images/moyasar.png' width={36} alt='moyasar' /></li>
                                                        : ''
                                        )
                                    }

                                    <li><ALink href={content[2].maroof_url} target='_blank'><img src='/images/icons/maroof.svg' alt='maroof' /></ALink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 pt-2">
                            <div className="footer-contact">
                                <div className="widget">

                                    <h4 className="widget-title" dangerouslySetInnerHTML={{ __html: language.contactUs }}></h4>
                                    <ul className="footer-contact-list">
                                        <li className='footer-contact-item'>
                                            <div className="footer-contact-item-img">
                                                <img src='/images/icons/footer-whatsapp.svg' width={18} alt='whatsapp' />
                                            </div>
                                            <ALink href={`https://wa.me/${content[0].whatsapp}`}>{content[0].whatsapp}</ALink>
                                        </li>
                                        <li className='footer-contact-item'>
                                            <div className="footer-contact-item-img">
                                                <img src='/images/icons/footer-phone.svg' alt='footer-phone' />
                                            </div>
                                            <ALink href={`tel:${content[0].contactus_phone}`} dir="ltr">{content[0].contactus_phone}</ALink>
                                        </li>
                                        <li className='footer-contact-item'>
                                            <div className="footer-contact-item-img">
                                                <img src='/images/icons/footer-email.svg' width={18} alt='footer-email' />
                                            </div>
                                            <a href={`mailto:${content[0].contact_mail}`}>{content[0].contact_mail}</a>
                                        </li>
                                        <li className='footer-contact-item' style={{ alignItems: 'flex-start' }}>
                                            <div className="footer-contact-item-img m-0" style={{ width: "4rem" }}>
                                                <img src='/images/icons/map-pin.svg' width={22} style={{ position: "relative", left: "-3px" }} alt='map-pin' />
                                            </div>
                                            <span style={{ fontWeight: 500 }}>{content[0].contactus_address}</span>
                                        </li>
                                        <li className='footer-contact-item'>
                                            <div className="footer-contact-item-img me-2">
                                                <img src='/images/icons/time.svg' width={22} alt='time' />
                                            </div>
                                            <span style={{ fontWeight: 500 }}>{content[0].working_time}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom fw-normal text-center">
                <div className="container-fluid">
                    <p className="footer-copyright">{language.copyright}{(new Date()).getFullYear()} {language.allRightsReserved}</p>
                </div>
            </div>
            {
                showCookieBanner == "" ?
                    <div className="cookie-container">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-sm-12">
                                    <div className="cookie-container-text">
                                        {language.cookieText}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12 d-flex align-items-center">
                                    <div className="cookie-container-buttons">
                                        <button type='button' className='btn btn-primary' onClick={() => cookieBanner('approve')}>{language.approve}</button>
                                        <button type='button' className='btn btn-primary' onClick={() => cookieBanner('decline')}>{language.decline}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
            }
            <ALink href="/my-account/help" title="Support">
                <div className="footer-support">
                    <img src='/images/icons/support-chat.svg' width={36} alt='support-chat' />
                </div>
            </ALink>

            {
                content[0].whatsapp_show == 1 &&
                <div className="wtsp-contact">
                    <a href={`https://wa.me/${content[0].whatsapp}`} target={"_blank"} className="hotlinemp" style={{ cursor: "pointer" }}>
                        <div className="animated infinite zoomIn wtsp-contact-circle" />
                        <div className="animated infinite pulse wtsp-contact-circle-fill" />
                        <div className="animated infinite tada wtsp-contact-img-circle" />
                    </a>
                </div>
            }

        </footer>
    );
}

export default React.memo(Footer);